/* New Dashboard Css for Universal Form */

.overall-QA-Eval-data {
    display: flex;
    justify-content: space-around;
    padding: 30px 0 20px 0;
    background: #fff;
}

.overall-QA-Eval-btn {
    display: flex;
    justify-content: space-around;
    margin: 0px 6px;
    padding: 12px 15px;
    background: #0684cf;
    color: #fff;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
}

.chart-heading {
    text-align: center;
    padding: 16px 0;
    font-size: 18px;
    font-family: "Source Sans Pro";
    font-weight: bold;
    text-anchor: middle;
    color: #524f4f;
}

.chart-loader {
    position: absolute;
    background: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 1;
}

.chart-main {
    position: relative;
}

.US-drawer-footer{
    background: #fff;
    height: 7rem;
    width: 100%;
    bottom: 0px;
    position: fixed;
    z-index: 100;
    margin-top: -21px;
    padding: 2rem;
    border-top: 1px solid #dcdcdc;
    text-align: right;
}
.uniFormCardLink{
    font-size:11px;
    color: currentColor !important;
    text-decoration: underline !important;
}
/* New Dashboard Css for Universal Form */