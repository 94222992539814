* {
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .container {
    margin: 20px auto;
    max-width: 900px;
  }
  
  .heatmap {
    display: grid;
    grid-gap: 5px;
    align-items: start;
  }
  
  .col {
    display: grid;
    grid-gap: 5px;
  }
  
  .cell {
    height: 15px;
    width: 100%;
    position: relative;
    background: hsl(196deg 36% 95%);
  }
  
  .label {
    text-align: center;
    height: 15px;
    font-size: 14px;
  }
  
  .vertical {
    max-width: 400px;
    grid-template-columns: repeat(8, 1fr);
  }
  
  .vertical .col {
    flex-direction: column;
  }
  
  .horizontal {
    max-width: 900px;
    grid-template-rows: repeat(8, 1fr);
  }
  
  .horizontal .col {
    display: grid;
    grid-template-columns: repeat(25, 1fr);
  }
  
  .horizontal .cells .label {
    text-align: left;
  }
  
  .tooltip {
    position: absolute;
    display: none;
    background: white;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 1;
    top: 50%;
    left: 100%;
    font-size: 18px;
    border-radius: 4px;
    min-width: 110px;
  }

  /* Application Category tooltip */

.app-cat-tooltip {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid #ccc;
  padding: 0px;
  z-index: 1070;
  top: 50%;
  left: 100%;
  font-size: 12px;
  border-radius: 6px;
  min-width: 110px;
}
.app-cat-tooltip .tooltip-inner {
  max-width: 200px;
  padding: 6px;
  color: #000000;
  text-align: center;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
}
.app-cat-tooltip.show {
  display: block;
  opacity: 1;
}

.app-cat-tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  margin-left: -7px;
  border-style: solid;
  border-width: 7px 7px 7px 0;
  border-color: transparent #e1d7d7 transparent transparent;
}



.app-attr-tooltip {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid #ccc;
  padding: 0px;
  z-index: 1070;
  top: 50%;
  left: 100%;
  font-size: 12px;
  border-radius: 4px;
  min-width: 110px;
}
.app-attr-tooltip .tooltip-inner {
  max-width: 200px;
  padding: 3px;
  color: #000000;
  text-align: center;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
}
.app-attr-tooltip.show {
  display: block;
  opacity: 1;
}

.app-attr-tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  margin-left: -8px;
  border-style: solid;
  border-width: 8px 9px 8px 0;
  border-color: transparent #e1d7d7 transparent transparent;
}
  
  .tooltip span {
    display: block;
    font-size: 14px;
  }
  
  .tooltip .count {
    font-size: 18px;
    font-weight: 700;
  }
  
  .cell:hover .tooltip {
    display: block;
  }
  
  .legend {
    margin-top: 30px;
    max-width: 300px;
  }
  
  .legend .cell {
    height: 15px;
    width: 100%;
  }
  
  .legend .labels {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }
  
  .legend span {
    font-size: 16px;
  }
  