.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.overall-data {
  display: flex;
  justify-content: center;
  padding: 0 0 20px 0;
  background: #fff;
}

.overall-data-btn {
  display: flex;
  justify-content: space-around;
  margin: 0px 6px;
  padding: 12px 15px;
  background: #0684cf;
  color: #fff;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rdt_TableHeadRow {
  background-color: #0d9dd9 !important;
  color: white;
}

.charts {
  box-shadow: 0 0 16px rgb(0 0 0 / 15%);
}

.css-tr4s17-option {
  background-color: #cdcdcd54 !important;
  color: black !important;
}

.css-7gzria-control {
  border-color: #b1b1b1 !important;
  box-shadow: 0 0 0 0 #b1b1b1 !important;
}

.css-s2ti5p-control:hover {
  border-color: #b1b1b1 !important;
  box-shadow: 0 0 0 0 #b1b1b1 !important;
}

.css-s2ti5p-control:focus {
  border-color: #b1b1b1 !important;
  box-shadow: 0 0 0 0 #b1b1b1 !important;
}

.css-s2ti5p-control:focus-out {
  border-color: #b1b1b1 !important;
  box-shadow: 0 0 0 0 #b1b1b1 !important;
}

.css-s2ti5p-control {
  border-color: #b1b1b1 !important;
  box-shadow: 0 0 0 0 #b1b1b1 !important;
}

.csv-button {

  font-size: 11px !important;
  height: 20px !important;
  padding-top: 2px !important;


}

.pdf-button {

  font-size: 11px !important;
  height: 20px !important;
  padding-top: 2px !important;

}

.export-menu {
  min-width: 7.2rem !important;
}

.xls-button {

  font-size: 11px !important;
  height: 20px !important;
  padding-top: 2px !important;
}

.content-container {
  display: flex;
  justify-content: flex-end;
}

/* viewport multiscreen css start */
.viewport-section {
  padding: 20px 0 0;
}

.viewport-section .vs-left {
  /* height: calc(100% - 2.8rem); */
  height: calc(100vh - 14.6rem);
  /* height: 90vh; */
}

.call-box.ip-box {
  height: calc(100% - 16.5rem);
  background-color: transparent;
}
.active-opt{
  background-color: #0684CF;
  color: white;
  cursor: pointer;
}
.de-active-opt{
  background-color: #b9b9b9;
  color: black;
  cursor: pointer;
}
.search-box .searchbox-check {
  margin: 0.8rem 0.5rem 0 0;
}

.searchbox-check .search-check-label {
  margin: 0;
  padding: 0.8rem 1.5rem;
  border: 1px solid #dcdcdc;
  background-color: #fff;
  border-radius: 20px;
  text-transform: capitalize;
  min-width: 76px;
  text-align: center;
  cursor: pointer;
  color: #464646;
}

.searchbox-check .search-check-label:hover {
  color: #232323;
  border-color: #b0b0b0;
}

.searchbox-check input[type="checkbox"]:checked~.search-check-label,
.searchbox-check input[type="checkbox"]:checked~.search-check-label:hover {
  border-color: var(--ip-primary);
  color: var(--ip-primary);
}

.search-box .btn-clear {
  padding: 0;
}

.search-box .btn-clear:hover {
  color: var(--ip-primary);
}

.viewport-section .call-box .box-contents {
  overflow: auto;
}

.viewport-section .call-results {
  font-size: 1.8rem;
  margin-bottom: 2rem;
}

.viewport-section .call-results label {
  font-size: inherit;
  margin: 0;
}

.viewport-section .call-card {
  background-color: #ffff;
  padding: 1.2rem 1.5rem;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 1rem;
  border: 1px solid transparent;
}

.viewport-section .call-card:hover {
  border-color: var(--ip-primary);
}

.viewport-section .call-card:last-child {
  margin-bottom: 0;
}

.call-card .cc-right {
  max-width: calc(100% - 3.2rem);
}

.call-card .cc-title {
  font-size: 1.6rem;
  color: var(--ip-primary);
  margin-bottom: 1rem;
  font-weight: 600;
}

.call-card .cc-date {
  font-weight: 600;
  color: #464646;
  margin-bottom: 0.5rem;
}

.call-card .cc-contents {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  color: #666;
}

.call-box .custom-checkbox {
  padding-top: 0.2rem;
}

.call-box .call-rheader .custom-checkbox {
  padding: 0.3rem 1rem 0 1.6rem;
}

.call-box .custom-checkbox label,
.call-box .custom-checkbox .custom-control-label {
  padding: 0;
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.call-box .custom-checkbox label:before,
.call-box .custom-checkbox .custom-control-label:before {
  background-color: transparent;
}

.call-box .custom-checkbox .custom-control-input:checked~label:before,
.call-box .custom-checkbox .custom-control-input:checked~.custom-control-label:before {
  background-color: var(--ip-primary);
}

.viewport-section .vs-top,
.viewport-section .vs-bottom {
  /* height: calc(50vh); */
  /* height: auto; */
  background-color: #fff;
}

.viewport-section2 {
  /* height: calc(50vh - 7.1rem); */
  height: 77rem;
  background-color: #fff;
}

.viewport-section .vs-top {
  margin-bottom: 1rem;
}

.vs-right .ip-box {
  height: 100%;
}

.vs-right .box-contents {
  overflow: auto;
}

.vs-right .vs-bottom .box-contents {
  overflow: hidden;
  height: calc(100% - 55px);
}

.viewport-section .vs-bottom .box-header {
  padding-bottom: 0;
  border-bottom: 0;
}

.vs-top .form-meta .form-group {
  margin-bottom: 1rem;
}

.vs-top .form-meta label {
  padding-left: 0;
  font-size: 1rem;
  margin-bottom: 0;
  color: #646464;
}

.vs-top .form-meta .multiselect-container>li>a>label {
  padding-left: 1.2rem;
  font-size: 1.2rem;
}

.vs-top .form-meta .form-control,
.vs-top .form-meta .multiselect.btn {
  border: 0;
  border-bottom: 1px solid #dcdcdc;
  border-radius: 0;
  padding: 0;
  height: 22px;
  color: #000;
  font-weight: 500;
}

.viewport-section .vs-bottom audio {
  display: block;
  width: 100%;
  height: 40px;
}

.viewport-section .call-box .box-contents::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}

.viewport-section .call-box .box-contents::-webkit-scrollbar-thumb {
  background-color: var(--ip-primary);
  background-color: #868686;
  border-radius: 3px;
}

.viewport-section .call-box .box-contents::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f0f0f0;
}

.viewport-section .vs-top .box-contents::-webkit-scrollbar-track .viewport-section .vs-bottom .tab-content::-webkit-scrollbar-track {
  background-color: #fff;
}

.viewport-section .vs-top .box-contents::-webkit-scrollbar-thumb,
.viewport-section .vs-bottom .tab-content::-webkit-scrollbar-thumb {
  background-color: var(--ip-primary);
  background-color: #868686;
  border-radius: 3px;
}

.viewport-section .vs-top .box-contents::-webkit-scrollbar,
.viewport-section .vs-bottom .tab-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #fff;
}

.viewport-section .tt-area {
  height: 100%;
}

.viewport-section .tt-area>.tab-content {
  height: calc(100% - 40px);
  overflow: auto;
  padding: 10px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
}

.nav-tabs.tc-tabs,
.nav-tabs.ov-tabs {
  border: 0;
  margin-bottom: 10px;
}

.nav-tabs.tc-tabs .nav-item,
.nav-tabs.ov-tabs .nav-item {
  margin-bottom: 0;
  margin-right: 10px;
}

.nav-tabs.tc-tabs .nav-link,
.nav-tabs.ov-tabs .nav-link {
  border: 1px solid #dcdcdc;
  border-radius: 30px;
  font-weight: 500;
  color: #646464;
}

.nav-tabs.ov-tabs .nav-link {
  padding: 4px 10px;
  font-size: 1rem;
}

.nav-tabs.tc-tabs .nav-link:hover,
.nav-tabs.ov-tabs .nav-link:hover {
  border-color: var(--ip-primary);
  color: var(--ip-primary);
}

.nav-tabs.tc-tabs .nav-link.active,
.nav-tabs.tc-tabs .nav-link.active:hover,
.nav-tabs.ov-tabs .nav-link.active,
.nav-tabs.ov-tabs .nav-link.active:hover {
  background-color: var(--ip-primary);
  border-color: transparent;
  color: #fff;
}

.chatbot-msg p {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 500;

}

.chatbot-msg p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;

}

.viewport-section .d-link {
  font-size: 1rem;
  border: 1px solid #ccc;
  padding: 4px 8px;
  border-radius: 5px;
  display: inline-block;
}

.viewport-section .file-box {
  background-color: #e3e3e3;
  padding: 14px;
  border-radius: 5px;
  max-height: 100%;
  width: 48%;
  margin: 3px 1%;
}

.add-tag-btn-2 {
  font-size: 9px;
  height: 27px;
  padding-top: 4px;
  padding-left: 10px;
  margin-left: -25px;
  width: 40px;
}

/* viewport form */
.form-box.ip-box {
  height: 100%;
}

.viewport-section .form-box .box-contents {
  overflow: auto;
}
.bxc{
  max-height: 650px!important;
}

.viewport-section .custom-table {
  width: 100%;
  display: table;
  border: 1px solid #f0f0f0;
  border-collapse: collapse;
}

.viewport-section .custom-table .row {
  display: table-row;
  margin-left: -10px;
  margin-right: -10px;
}

.viewport-section .custom-table .col {
  display: table-cell;
  border: 1px solid #f0f0f0;
  padding: 6px 10px;
  font-weight: 600;
}

.viewport-section .custom-table .col-title {
  color: #646464;
  font-size: 1.1rem;
}

.viewport-section .custom-table .col-contents {
  min-width: 100px;
  text-align: center;
  font-size: 1.1rem
}

.attribute-details .attribute-title {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
}

.attribute-details .ats-tag {
  font-size: 9px;
  padding: 2px 10px;
  border: 1px solid #d0d0d0;
  border-radius: 14px;
  margin-left: 3px;
  display: inline-block;
  color: #000;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.3;
  vertical-align: text-bottom;
}

.attribute-details .ats-tag.required {
  color: #E53935;
  border-color: #E53935;
  margin-left: 10px;
}

.attribute-details .ats-tag.score {
  color: green;
  border-color: green;
}

.score-input {
  background: none;
  border: none;
  font-weight: 600;
  color: #0d9dd9;
  text-align: right;
}
.score-input:focus-within{
  outline: transparent;
}
.score-input:focus-visible{
  outline: transparent;
}


.attribute-details .ats-tag.noscore {
  color: #888;
}

.attribute-title .ats-tag:first-child {
  margin-left: 10px;
}

.attribute-details .form-check {
  margin-top: 6px;
}

.attribute-details .form-check .form-check-input {
  cursor: pointer;
}

.attribute-area .subattribute-area {
  /* display: none; */
  margin-top: 10px;
}

.subattribute-area .attribute-area {
  padding-left: 0;
  padding-right: 0;
}

.subattribute-area .attribute-area:last-child {
  border-bottom: 0;
}

.viewport-section .escalation-comments-area {
  margin-top: 2rem;
  border-radius: 6px;
  padding-bottom: 0;
}

.escalation-activity td,
.escalation-activity th {
  font-size: 1rem;
}

.escalation-comments-area .attr-ad-btn {
  font-size: 10px;
  margin-top: 6px;
  padding: 2px 8px;
  border-radius: 15px;
}

.escalation-comments-area .accept {
  border: 1px solid #0d9dd9;
}

.escalation-comments-area .decline {
  border: 1px solid #dc3545;
}

.score-counter-value {
  border: none;
  background: transparent;
  text-align: center;
  font-weight: 600;
}
.score-counter-value:focus-visible {
  outline: transparent;
}
.score-counter-value:focus-within {
  outline: transparent;
}

.gfont-size {
  font-size: 15px;
}

.vs-top2 {
  height: auto !important;
}

.call-card.with-border {
  border: 2px solid #007bff;
  /* You can adjust the border color and width */
}

#sf2 {
  width: 110%;
  height: 50px;
  margin-top: -20px;
  margin-left: -24px;
}
#sf3 {
  width: 85%;
  height: 40px;
}
#sf3::-webkit-media-controls-time-remaining{
  margin-left: 120px;
}
#sf3::-webkit-media-controls-current-time-display {
  margin-left: 30px;
}
#file-tab{
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
#app-tab{
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
#tags-tab{
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

#json{
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
#mp3{
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
#text{
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.call-details-audio{
  width: 30%!important;
  margin-top: 5px!important;

}

/* Style the audio controls */
#sf2::-webkit-media-controls-panel {
  background-color: #ffffff; /* Change to the color you want */
  box-shadow: none!important;
  
 
}
#sf3::-webkit-media-controls-panel {
  background-color: #ffffff; /* Change to the color you want */
  box-shadow: none;
 
}
#sf2::-webkit-media-controls-enclosure {
  border-radius: 100px; /* Change to the color you want */ 
}


#sf2::-webkit-media-controls-play-button {
 margin-left: 18px;
}
#sf3::-webkit-media-controls-play-button {
  margin-left: 10.5px;
 }

#sf2::-webkit-media-controls-current-time-display {
  margin-left: 32px;
  font-weight: 900;
}
#sf2::-webkit-media-controls-time-remaining-display {
 
  font-weight: 900;
}
.g-font{
  font-size: 10px;
}
.font-styling{
  font-weight: bold;
 color: rgb(22, 177, 22);
}
.active-form{
width:40px;
height:12px;
background-color:rgb(22, 177, 22);
border-radius:100px
}
.deactive-form{
width:40px;
height:12px;
background-color:rgb(136, 136, 136);
border-radius:100px
}
.active-form-inside{
width:10px;
height:11px;
background-color:white;
border-radius:100px;
margin-Left:"28px"
}
.deactive-form-inside{
width:10px;
height:11px;
background-color:white;
border-radius:100px;
margin-Left:"28px"
}
.module-name{
  font-weight: 550;
  font-size: 13px;
 
}
.sub-module-name{
  font-weight: 500;
  font-size: 12px;
  color: #0889D2;
  padding-left: 10px;
  padding-top: 5px;

}
.chart_zoomin_btn{
  display: none;
}



















/* Style custom play and pause icons */




/* Style the audio player controls */

/* Add this CSS to your stylesheet */


/* viewport multiscreen css end */