@import url('https://fonts.googleapis.com/css?family=Open+Sans|Nova+Mono');
:root {
  --font-header: 'Nova Mono', monospace;
  --font-text: 'Open Sans', sans-serif;
  --color-theme: #282B24;
}
.noaccess {
  text-align: center;
  margin: 30rem 10rem;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  position: fixed;
}
.noaccess h1 {
  font-family: var(--font-header);
  font-size: calc(4rem + 2vw);
  text-transform: uppercase;
  color: var(--color-theme);
}
.noaccess p {
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 2rem;
  margin: 1.5rem 0 3rem;
}